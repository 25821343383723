import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './app/App';
import {ErrorBoundary} from "react-error-boundary";
import RootErrorFallback from "./components/errors/RootErrorFallback";
import {BrowserRouter as Router} from "react-router-dom";
import invariant from 'tiny-invariant';

const body = document.getElementsByTagName('body')[0];
invariant(body, 'body element should exists');

body.className = 'bg-cyan-950 w-full h-full';

const rootElement = document.getElementById('root');
invariant(rootElement, 'root element should exists');

const root = ReactDOM.createRoot(rootElement as HTMLElement);
root.render(
  <React.StrictMode>
    <ErrorBoundary FallbackComponent={RootErrorFallback}>
      <Router>
        <App/>
      </Router>
    </ErrorBoundary>
  </React.StrictMode>
);
