import {useCallback, useLayoutEffect, useState} from "react";
import * as jwt_decode from 'jwt-decode';

type AuthHookReturnType = {
  token: string | null;
  username: string | null;
  login: (token: string, username: string, remember: boolean) => void;
  logout: () => void;
  isAuthenticated: () => boolean;
};

// Utilitaires pour manipuler les cookies
const setCookie = (name: string, value: string, days: number) => {
  const expires = new Date();
  expires.setTime(expires.getTime() + days * 24 * 60 * 60 * 1000);
  document.cookie = `${name}=${value};expires=${expires.toUTCString()};path=/`;
};

const getCookie = (name: string): string | null => {
  const match = document.cookie.match(new RegExp("(^|;\\s*)" + name + "=([^;]*)"));
  return match ? match[2] : null;
};

const deleteCookie = (name: string) => {
  document.cookie = `${name}=; Max-Age=0; path=/`;
};

// Hook pour gérer l'authentification
export const useAuth = (): AuthHookReturnType => {
  const [token, setToken] = useState<string | null>(localStorage.getItem("authToken") ?? null);
  const [username, setUsername] = useState<string | null>(getCookie("username") ?? null);

  // check if the token is still valid and if not, remove it
  useLayoutEffect(() => {
    if (token) {
      const payload = jwt_decode.jwtDecode(token) as { exp: number };
      if (payload.exp < Date.now() / 1000) {
        localStorage.removeItem("authToken");
        setToken(null);
      }
    }
  }, [token]);


  // Fonction pour se connecter
  const login = (token: string, username: string, remember: boolean = false) => {
    localStorage.setItem("authToken", token);
    setToken(token);
    if (remember) {
      setCookie("username", username, 7);
      setUsername(username);
    } else {
      deleteCookie("username");
      setUsername(null);
    }
  };

  // Fonction pour se déconnecter
  const logout = () => {
    localStorage.removeItem("authToken");
    setToken(null);
  };

  // Vérifier si l'utilisateur est authentifié
  const isAuthenticated = useCallback(() => !!token, [token]);

  return {
    token,
    username,
    login,
    logout,
    isAuthenticated,
  };
};
