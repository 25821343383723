import React, {ReactNode, useCallback, useEffect, useState} from 'react';
import {Observable, Subject} from 'rxjs';

export interface AlertTrigger {
  margin?: string;
  position?: string;
  height?: string;
  width?: string;
  timer?: number;
  overlay?: boolean;
  color?: { text?: string; border?: string; background?: string, timeBar?: string };
  content?: ReactNode;
}

const alertSubject = new Subject<AlertTrigger>();

export const useAlert = () => {
  const [alert, setAlert] = useState<AlertTrigger | null>(null);

  // Fonction générique pour envoyer une alerte
  const sendAlert = useCallback((alert: AlertTrigger) => {
    alertSubject.next(alert);
  }, []);

  // Fonction pour envoyer une alerte avec du texte
  const notify = useCallback((text: ReactNode, options?: Omit<AlertTrigger, 'content' | 'overlay'>) => {
    sendAlert({
      height: options?.height ?? '80px',
      width: options?.width ?? '100%',
      position: options?.position ?? 'top',
      margin: options?.margin ?? '10px',
      timer: options?.timer ?? 5000,
      color: options?.color,
      content: (
        <div className="flex w-full h-full space-x-5 justify-center items-center">
          {/*<FontAwesomeIcon className="h-10 text-blue-500" icon={solid('info-circle')} />*/}
          <div className="font-medium text-lg">
            <span>{text}</span>
          </div>
        </div>
      ),
    });
  }, [sendAlert]);

  // Fonction pour envoyer une alerte avec un overlay personnalisé
  const overlay = useCallback((content: ReactNode, color?: AlertTrigger['color']) => {
    sendAlert({
      height: 'content-fit',
      width: 'content-fit',
      overlay: true,
      position: 'center',
      margin: '10px',
      color,
      content,
    });
  }, [sendAlert]);

  // Abonnement aux alertes
  useEffect(() => {
    const subscription = alertSubject.asObservable().subscribe(setAlert);
    return () => subscription.unsubscribe();
  }, []);

  // Permet de s'abonner aux alertes via un Observable
  const observable = useCallback((): Observable<AlertTrigger> => {
    return alertSubject.asObservable();
  }, []);

  // Suppression automatique des alertes après un certain temps
  useEffect(() => {
    if (alert?.timer) {
      const timerId = setTimeout(() => setAlert(null), alert.timer);
      return () => clearTimeout(timerId);
    }
  }, [alert]);

  return {
    alert,
    notify,
    overlay,
    observable,
  };
};
