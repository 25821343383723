import React, {useCallback, useEffect, useRef, useState} from 'react';
import {AlertTrigger, useAlert} from '../../utils/hooks/alert.hook';
import {IconX} from "@tabler/icons-react";

const Alert: React.FC = () => {
  const {observable} = useAlert();
  const [trigger, setTrigger] = useState<AlertTrigger | undefined>(undefined);
  const alertElement = useRef<HTMLDivElement>(null);
  const timerBarElement = useRef<HTMLDivElement>(null);

  // Define timeout and interval as refs to persist across renders
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const intervalRef = useRef<NodeJS.Timeout | null>(null);

  const close = () => {
    if (intervalRef.current) clearInterval(intervalRef.current);
    if (timeoutRef.current) clearTimeout(timeoutRef.current);
    if (timerBarElement.current) timerBarElement.current.style.width = '';
    setTrigger(undefined);
  };

  const closeHandler = useCallback((event: MouseEvent) => {
    if (trigger?.overlay && alertElement.current && !alertElement.current.contains(event.target as Node)) {
      close();
    }
  }, [alertElement, trigger?.overlay]);

  const getPosition = (position: string | undefined) => {
    position = position ?? 'center';
    switch (position) {
      case 'bottom':
        return 'justify-center items-end';
      case 'center':
        return 'justify-center items-center';
      case 'top':
        return 'justify-center items-start';
      case 'bottom-right':
        return 'justify-end items-end';
      case 'center-right':
        return 'justify-end items-center';
      case 'top-right':
        return 'justify-end items-start';
      case 'bottom-left':
        return 'justify-start items-end';
      case 'center-left':
        return 'justify-start items-center';
      case 'top-left':
        return 'justify-start items-start';
    }
  };

  useEffect(() => {
    const sub = observable().subscribe((alert: AlertTrigger) => {
      close();
      setTrigger(alert);
    });

    return () => {
      // Cleanup on unmount
      if (sub) sub.unsubscribe();
      document.removeEventListener('click', closeHandler);
    };
  }, [closeHandler, observable]);

  useEffect(() => {
    if (trigger) {
      setTimeout(() => document.addEventListener('click', closeHandler), 250);
      let count = 1;
      if (trigger.timer) {
        clearTimeout(timeoutRef.current as NodeJS.Timeout);
        clearInterval(intervalRef.current as NodeJS.Timeout);
        timeoutRef.current = setTimeout(() => close(), trigger.timer);
        intervalRef.current = setInterval(() => {
          if (timerBarElement.current && trigger.timer) {
            timerBarElement.current.style.width = (count++ * 20 / trigger.timer * 100).toString() + '%';
            if (count * 20 / trigger.timer * 100 > 100) close();
          }
        }, 20);
      }
    } else {
      document.removeEventListener('click', closeHandler);
    }
  }, [trigger, closeHandler]);

  return (
    <div
      className={[
        'absolute flex w-full h-full z-50 pointer-events-none',
        getPosition(trigger?.position),
        !trigger ? 'hidden' : '',
        trigger?.overlay ? 'bg-black/50 backdrop-blur-sm' : '',
      ].join(' ')}
      style={{padding: trigger?.margin}}
    >
      <div
        ref={alertElement}
        style={{width: trigger?.width, height: trigger?.height}}
        className={[
          'relative pointer-events-auto border overflow-hidden rounded-lg shadow-md shadow-gray-400 border-gray-300 text-sm group',
          trigger?.color?.text ?? 'text-black',
          trigger?.color?.border ?? 'border-gray-300',
          trigger?.color?.background ?? 'bg-gray-100',
        ].join(' ')}
      >
        <div
          className={[
            'w-full h-full',
            !trigger?.overlay ? 'px-10' : '',
            trigger?.timer ? 'pb-1.5' : '',
          ].join(' ')}
        >
          {trigger?.content}
        </div>
        <div
          className={[
            'absolute bottom-0 inset-x-0 flex h-1.5',
            !trigger?.timer ? 'hidden' : '',
          ].join(' ')}
        >
          <div ref={timerBarElement} className={`${trigger?.color?.timeBar ?? 'bg-gray-500'} rounded-bl`}></div>
          <div className="grow bg-gray-300 rounded-br"></div>
        </div>
        <div
          onClick={close}
          className={[
            'absolute items-center right-3 top-3 hover:text-gray-700 cursor-pointer',
            trigger?.overlay ? 'flex' : 'hidden group-hover:flex',
          ].join(' ')}
        >
          <IconX className={'h-5'}/>
        </div>
      </div>
    </div>
  );
};

export default Alert;
