import {Link, useLocation} from "react-router-dom";
import {IconBook, IconLogout, IconTag, IconUserCircle} from "@tabler/icons-react";
import {useCallback, useContext, useRef, useState} from "react";
import UserContext from "../../context/user.context";
import {useClickOutside} from "../../utils/hooks/clickOutside.hook";

function Navbar() {

  const user = useContext(UserContext)
  const [profile, setProfile] = useState<boolean>(false);
  const ref = useRef<HTMLDivElement>(null);
  const location = useLocation()

  const outerClick = useCallback(() => {
    setProfile(false);
  }, []);

  useClickOutside(ref, outerClick);

  const handleProfile = useCallback(() => {
    setProfile(!profile);
  }, [profile]);

  return (
    <>
      <nav className="relative bg-cyan-600 w-full flex justify-between items-center mx-auto px-8 h-20">
        <div className="flex gap-5 content-center text-white">
          <Link to={'/'} className={'font-black text-2xl'}>Toon Tracker</Link>
          <div className={'pl-2 flex gap-5 '}>
            <Link to={'/'} className={`py-1 cursor-pointer ${location.pathname === '/' ? 'text-cyan-900' : ''}`}><IconBook size={28}/></Link>
            <Link to={'/tag'} className={`py-1 cursor-pointer ${location.pathname.startsWith('/tag') ? 'text-cyan-900' : ''}`}><IconTag size={28}/></Link>
          </div>
        </div>

        <div className="flex-initial">
          <div className="flex justify-end items-center relative">
            <div className="block">
              <div className="inline relative">
                <button type="button" onClick={handleProfile} className={`text-white inline-flex items-center relative px-2 border border-white rounded-full hover:shadow-md bg-cyan-800 hover:bg-cyan-900`}>
                  <div className="pl-2 font-medium">{user?.username}</div>
                  <div className="block flex-grow-0 flex-shrink-0 h-10 w-10 pl-2 py-1">
                    <IconUserCircle size={34} stroke={1.25}/>
                  </div>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div ref={ref} className={`absolute top-16 mt-1.5 font-medium border border-black shadow shadow-gray-700 right-0.5 bg-cyan-500 w-48 rounded-md ${!profile ? 'hidden' : ''}`}>
          <div className="block">
            <Link to={'/logout'} className={'flex gap-2 px-4 py-2 hover:bg-cyan-600'}>
              <IconLogout/><span>Logout</span>
            </Link>
          </div>
        </div>
      </nav>
    </>

  );
}

export default Navbar;