import {ErrorBoundary} from "react-error-boundary";
import AppRoutingErrorFallback from "../components/errors/AppRoutingErrorFallback";
import {Route, Routes} from "react-router-dom";
import {lazy, Suspense} from "react";
import Alert from "../components/alert/Alert";
import PrivateRoutes from "./PrivateRoutes";

const Login = lazy(() => import('../views/login/login.routing'));
const Logout = lazy(() => import('../views/logout/logout.routing'));
const Register = lazy(() => import('../views/register/register.routing'));
const Home = lazy(() => import('../views/home/home.routing'));
const Tag = lazy(() => import('../views/tag/tag.routing'));

export function AppRouting() {

  return (
    <ErrorBoundary FallbackComponent={AppRoutingErrorFallback}>
      <Suspense>
        <Alert/>
        <Routes>
          <Route element={<Logout/>} path="logout/*"/>
          <Route element={<Login/>} path="login/*"/>
          <Route element={<Register/>} path="register/*"/>
          <Route element={<PrivateRoutes/>}>
            <Route element={<Tag/>} path="tag/*"/>
            <Route element={<Home/>} path="*"/>
          </Route>
        </Routes>
      </Suspense>
    </ErrorBoundary>
  );
}

export default AppRouting;
