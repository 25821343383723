import React from 'react';
import './App.css';
import {Route, Routes} from "react-router-dom";
import AppRouting from "./app.routing";

function App() {
  return (
    <Routes>
      <Route path="*" element={<AppRouting/>}/>
    </Routes>
  );
}

export default App;
