import {useCallback, useEffect, useState} from 'react';
import axios, {AxiosRequestConfig, AxiosResponse, Method} from 'axios';
import {ExceptionDto} from "common/dto/exception.dto";
import {useAuth} from "./auth.hook";

// Définir l'URL de base
const BASE_URL = process.env.NODE_ENV === 'production' ? '/api' : 'http://localhost:4200/api';

interface UseAxiosOptions<B = unknown, P = unknown> {
  method: Method;            // Méthode HTTP (GET, POST, PUT, DELETE, etc.)
  url: string;               // URL spécifique à l'appel
  body?: B;                  // Body (facultatif pour les méthodes POST, PUT, etc.)
  params?: P;                // Paramètres de requête (facultatifs)
  headers?: Record<string, string>;  // En-têtes (facultatifs)
  autoFetch?: boolean;       // Permet d'activer ou non la requête au premier rendu (par défaut : true)
}

interface UseAxiosReturn<T, B> {
  data: AxiosResponse<T> | null;            // Données retournées par la requête
  error: ExceptionDto | null;      // Erreur retournée par la requête
  loading: boolean;          // État de chargement
  fetchData: (body: B | undefined) => Promise<void>; // Fonction pour déclencher manuellement la requête
}

// Hook générique avec typage du body, des params et des data de retour
export const useAxios = <T = unknown, B = unknown, P = unknown>(
  {method, url, body: defaultBody, params, headers, autoFetch = true}: UseAxiosOptions<B, P>
): UseAxiosReturn<T, B> => {
  const [data, setData] = useState<AxiosResponse<T> | null>(null);
  const [error, setError] = useState<ExceptionDto | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const {token} = useAuth();

  // Fonction pour déclencher la requête
  const fetchData = useCallback(async (body?: B) => {
    setLoading(true);
    setError(null);
    try {
      const config: AxiosRequestConfig<B> = {
        method,
        url: `${BASE_URL}${url}`,  // Construction de l'URL finale
        data: body ?? defaultBody,
        params,
        headers: {
          ...headers,
          ...((token) ? {'Authorization': `Bearer ${localStorage.getItem('authToken')}`} : {}),
        }
      };

      const response: AxiosResponse<T> = await axios(config);
      setData(response);
    } catch (err: any) {
      setError(err.response.data);
    } finally {
      setLoading(false);
    }
  }, [method, url, defaultBody, params, headers, token]);

  // Appel automatique si autoFetch est activé
  useEffect(() => {
    if (autoFetch) fetchData().then();
  }, [fetchData, autoFetch]);

  return {data, error, loading, fetchData};
};

