import {RefObject, useEffect} from 'react'

export const useClickOutside = (ref: RefObject<HTMLElement | undefined>, callback: (event: MouseEvent) => void) => {

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (ref.current && event.target && !ref.current.contains(event.target as Node)) {
        callback(event);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [ref, callback]);
}