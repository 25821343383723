import {FallbackProps} from 'react-error-boundary';

export default function AppRoutingErrorFallback({error}: FallbackProps) {
  return (
    <div className="grid h-screen place-content-center bg-slate-50 p-4">
      <div className="text-center h-auto">
        <h1 className="text-8xl font-black text-gray-200">Uh-oh!</h1>

        <p className="text-2xl font-bold tracking-tight text-gray-900 sm:text-4xl">Something went wrong</p>

        <p className="mt-4 text-gray-500">
          Uncaught Error:
          {error.message}
        </p>

        <div className="mt-12 py-2">
          <a href="/" className="bg-primary text-primary-foreground shadow hover:bg-primary/90 rounded h-9 px-4 py-2">
            Go Back Home
          </a>
        </div>
      </div>

      <div className="absolute bottom-2">
        <p className="mt-4 text-gray-500">
          If the problem persist, please advise
          {' '}
          <a href="mailto:benjamindelattre03@gmail.com" className="text-secondary hover:underline">Benjamin</a>.
          .
        </p>
      </div>
    </div>
  );
}
