import {useLayoutEffect, useState} from "react";
import {useAxios} from "./axios.hooks";
import {useAuth} from "./auth.hook";

export const useUser = (): any | null => {
  const [user, setUser] = useState<any | null>(null);
  const {isAuthenticated} = useAuth();

  const {data, fetchData} = useAxios<any>({
    method: 'get',
    url: '/auth/profile',
    autoFetch: false,
  });

  useLayoutEffect(() => {
    if (isAuthenticated()) fetchData({}).then();
  }, [fetchData, isAuthenticated]);

  useLayoutEffect(() => {
    data && setUser(data.data);
  }, [data]);


  return user;

}