import {Outlet, useNavigate} from "react-router-dom";
import {useAuth} from "../utils/hooks/auth.hook";
import React, {useLayoutEffect} from "react";
import {useUser} from "../utils/hooks/user.hook";
import UserContext from "../context/user.context";
import Navbar from "../components/navbar/Navbar";


function PrivateRoutes() {

  const {isAuthenticated} = useAuth();
  const navigate = useNavigate();
  const user = useUser();

  useLayoutEffect(() => {
    if (!isAuthenticated()) navigate('/login');
  }, [isAuthenticated, navigate]);

  return (
    <UserContext.Provider value={user}>
      <Navbar/>
      <Outlet/>
    </UserContext.Provider>
  );
}

export default PrivateRoutes;